import React, { useState, useContext, useEffect } from "react"
import InquiryConfirm from "./InquiryConfirm"
import EmbroideredPatchesInquiry from "./ItemForms/EmbroideredPatchesInquiry"
import LapelPinsInquiry from "./ItemForms/LapelPinsInquiry"
import MedalsInquiry from "./ItemForms/MedalsInquiry"
import { Collapse, makeStyles, Paper } from "@material-ui/core"
import InquiryContext from "../context/InquiryContext"

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  instructions: {
    color: "textPrimary",
    align: "left",
    cariant: "caption",
  },
}))

const InquiryItems = ({ className }) => {
  const classes = useStyles()
  const { state, dispatch } = useContext(InquiryContext)
  const [items, setItems] = useState([])
  const itemSwitch = state.itemSwitch
  const [inquirySpecs, setInquirySpecs] = useState(false)

  useEffect(() => {
    setInquirySpecs(!inquirySpecs)
  }, [])
  const itemChoices = itemSwitch => {
    switch (itemSwitch) {
      case "patches":
        return <EmbroideredPatchesInquiry setItems={setItems} />
      case "pins":
        return <LapelPinsInquiry setItems={setItems} />
      case "medals":
        return <MedalsInquiry setItems={setItems} />
      default:
        return "error, try again"
    }
  }
  if (itemSwitch == "inactive") return ""
  if (itemSwitch == "added") return <InquiryConfirm items={items} />
  return (
    <>
      <div mt={3}>
        <Collapse in={inquirySpecs} exit>
          <Paper square elevation={0} className={classes.paper}>
            {itemChoices(itemSwitch)}
          </Paper>
          {/* <InquiryConfirm items={items} /> */}
        </Collapse>
      </div>
    </>
  )
}

export default InquiryItems
