import React, { useState, useContext } from "react"
import { makeStyles } from "@material-ui/core/styles"

import {
  Button,
  Collapse,
  Divider,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core"

import CheckBoxIcon from "@material-ui/icons/CheckBox"

import gql from "graphql-tag"
import { useMutation } from "@apollo/client"

import NoSsr from "@material-ui/core/NoSsr"
import MuiPhoneNumber from "mui-phone-input-ssr"
// import MuiPhoneInput from "material-ui-phone-number"
import InquiryContext from "../../context/InquiryContext"
import useDidMountEffect from "../../../utils/hooks/useDidMountEffect"
import { useSiteMetadata } from "../../../utils/hooks/useSiteMetadata"

const phoneTypes = [
  { id: 1, name: "Mobile" },
  { id: 2, name: "Office" },
  { id: 3, name: "Home" },
  { id: 4, name: "App" },
]
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  formSection: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  infoSection: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  title: {},
}))
const InquiryPersonInfoForm = ({ firstName, setFirstName, setHasPerson }) => {
  const classes = useStyles()
  const { state, dispatch } = useContext(InquiryContext)
  const { sbuId } = useSiteMetadata()
  const [submitting, setSubmitting] = useState(false)
  const [personId, setPersonId] = useState(0)

  const [inquiryId, setInquiryId] = useState(0)
  const [inquiryUuid, setInquiryUuid] = useState("")

  const [lastName, setLastName] = useState("")
  const [org, setOrg] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [phoneType, setPhoneType] = useState(1)
  const [collapse, setCollapse] = useState(true)
  const { title } = useSiteMetadata()

  useDidMountEffect(() => {
    if (personId > 0) {
      handleNewInquiry()
      console.log("completed person and inquiry")
    } else console.log(personId)
  }, [personId])

  useDidMountEffect(() => {
    if (inquiryId > 0) {
      handleInquiryEmail()
      handleInquiryPhone()
      console.log("cool")
    } else console.log(inquiryId)
  }, [inquiryId])

  const [handleSubmit, { loading, error }] = useMutation(
    NEW_INQUIRY_PERSON_MUTATION,
    {
      variables: {
        firstName: firstName,
        middleName: "",
        lastName: lastName,
        nickName: "",
        title: "",
        isSupplier: false,
        isPerson: false,
        isOrg: false,
        orgName: org,
      },
      onCompleted: (data, response, errors) => {
        setPersonId(data.createInquiryPerson.inquiryPerson.id)
        setCollapse(false)
      },
      onError: err => console.error(err),
    }
  )

  const [handleNewInquiry] = useMutation(NEW_INQUIRY_MUTATION, {
    variables: {
      businessUnitId: sbuId,
      inquiryPersonId: personId,
      source: "iconemblem.com",
      spam: false,
      quoteCreated: false,
    },
    onCompleted: (data, errors) => {
      console.log(data)
      setInquiryUuid(data.createInquiry.inquiry.inquiryUuid)
      setInquiryId(data.createInquiry.inquiry.id)
      dispatch({
        type: "SET_INQUIRY_UUID",
        payload: data.createInquiry.inquiry.inquiryUuid,
      })
    },
    onError: err => console.error(err),
  })

  const [handleInquiryPhone] = useMutation(NEW_INQUIRY_PHONE_MUTATION, {
    variables: {
      phoneTypeId: 1,
      inquiryUuid: inquiryUuid,
      phoneNumber: phone,
    },

    onError: err => console.error(err),
  })

  const [handleInquiryEmail] = useMutation(NEW_INQUIRY_EMAIL_MUTATION, {
    variables: {
      emailAccount: email,
      inquiryUuid: inquiryUuid,
    },
    onCompleted: (data, errors) => {
      handleSectionChange()
    },
    onError: err => console.error(err),
  })

  function handlePhoneChange(value) {
    setPhone(value)
  }
  const handleSectionChange = event => {
    setHasPerson(true)
    dispatch({ type: "TOGGLE_SHOW_MENU" })
  }
  return (
    <Collapse in={collapse}>
      <div className={classes.infoSection}>
        <form
          onSubmit={event => {
            event.preventDefault()
            setSubmitting(true)
            handleSubmit(event)
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <FormControl required fullWidth>
                <TextField
                  label="First Name"
                  id="firstName"
                  helperText="(required)"
                  value={firstName}
                  onChange={event => setFirstName(event.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl required fullWidth>
                <TextField
                  label="Last Name"
                  id="lastName"
                  helperText="(required)"
                  value={lastName}
                  onChange={event => setLastName(event.target.value)}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField
                label="Organization"
                id="org"
                value={org}
                fullWidth
                onChange={event => setOrg(event.target.value)}
              />
            </Grid>
            <Divider />

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth required>
                <TextField
                  label="Email Address"
                  id="email"
                  type="email"
                  helperText="(required)"
                  value={email}
                  onChange={event => setEmail(event.target.value)}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <MuiPhoneNumber
                  label="Phone Number"
                  id="phone"
                  value="phone"
                  fullWidth
                  margin="dense"
                  size="small"
                  defaultCountry="us"
                  regions={["america", "europe", "asia"]}
                  disableAreaCodes
                  onChange={handlePhoneChange}
                />
                {/* <NoSsr>
                  <MuiPhoneInput
                    label="Phone Number"
                    id="phone"
                    value="phone"
                    fullWidth
                    defaultCountry="us"
                    regions={["america", "europe", "asia"]}
                    disableAreaCodes
                    onChange={handlePhoneChange}
                  />
                </NoSsr> */}
              </FormControl>
            </Grid>
            <Grid item xxs={12} zs={12}>
              <CheckBoxIcon style={{ fontSize: "19px" }} />
              <Typography style={{ display: "inline", padding: "10px" }}>
                <strong>No unnecessary emails, calls, or texts!</strong> And, of
                course, you're welcome to view our privacy policy.
              </Typography>
            </Grid>

            <Divider />

            <Grid item xs={12} sm={8}></Grid>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={
                    submitting ||
                    !firstName.trim() ||
                    !lastName.trim() ||
                    !email.trim()
                  }
                >
                  {loading ? "Starting inquiry..." : "Next: Select Items"}
                </Button>
              </FormControl>
            </Grid>
          </Grid>
        </form>
      </div>
    </Collapse>
  )
}
const NEW_INQUIRY_PERSON_MUTATION = gql`
  mutation(
    $firstName: String
    $lastName: String
    $middleName: String
    $suffix: String
    $nickname: String
    $title: String
    $isSupplier: Boolean
    $isPerson: Boolean
    $isOrg: Boolean
    $orgName: String
  ) {
    createInquiryPerson(
      firstName: $firstName
      lastName: $lastName
      middleName: $middleName
      suffix: $suffix
      nickname: $nickname
      title: $title
      isSupplier: $isSupplier
      isPerson: $isPerson
      isOrg: $isOrg
      orgName: $orgName
    ) {
      inquiryPerson {
        id
        firstName
        lastName
        isOrg
        orgName
      }
    }
  }
`

const NEW_INQUIRY_MUTATION = gql`
  mutation(
    $businessUnitId: Int
    $inquiryPersonId: Int
    $source: String
    $spam: Boolean
    $quoteCreated: Boolean
  ) {
    createInquiry(
      businessUnitId: $businessUnitId
      inquiryPersonId: $inquiryPersonId
      source: $source
      spam: $spam
      quoteCreated: $quoteCreated
    ) {
      inquiry {
        id
        inquiryUuid
        inquiryPerson {
          id
          firstName
          lastName
          orgName
        }
        createdAt
        businessUnit {
          id
          name
        }
      }
    }
  }
`
const NEW_INQUIRY_PHONE_MUTATION = gql`
  mutation($inquiryUuid: String!, $phoneTypeId: Int, $phoneNumber: String) {
    createInquiryPhoneNumber(
      inquiryUuid: $inquiryUuid
      phoneTypeId: $phoneTypeId
      phoneNumber: $phoneNumber
    ) {
      inquiryPhoneNumber {
        id
        phoneType {
          id
          phoneType
        }
        phoneNumber
      }
    }
  }
`
const NEW_INQUIRY_EMAIL_MUTATION = gql`
  mutation($inquiryUuid: String!, $emailAccount: String) {
    createInquiryEmailAccount(
      inquiryUuid: $inquiryUuid
      emailAccount: $emailAccount
    ) {
      inquiryEmailAccount {
        id
        inquiry {
          inquiryUuid
        }
        emailAccount
      }
    }
  }
`
export default InquiryPersonInfoForm
