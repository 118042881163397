import React, { useContext, useState } from "react"
import Collapse from "@material-ui/core/Collapse"
import Button from "@material-ui/core/Button"
import InquiryAddressForm from "./PersonForms/InquiryAddressForm"

import InquiryContext from "../context/InquiryContext"
const InquirySubmit = () => {
  const { state } = useContext(InquiryContext)
  const [samples, setSamples] = useState(false)
  const showSubmit = state.inquirySubmit

  return (
    <>
      <Collapse in={showSubmit}>
        We send samples if you want. Want samples?
        <Button onClick={() => setSamples(true)}>Yes samples</Button>
        <Button>No, thanks! Complete this Inquiry</Button>{" "}
      </Collapse>
      {samples && <InquiryAddressForm />}
    </>
  )
}

export default InquirySubmit
