import React from "react"
import InquiryForm from "./InquiryForm"

const Inquiry = ({}) => {
  return (
    <div>
      <InquiryForm />
    </div>
  )
}

export default Inquiry
