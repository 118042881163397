import React, { useContext } from "react"
import InquiryPerson from "./Forms/InquiryPerson"
import InquirySubmit from "./Forms/InquirySubmit"
import Menu from "./Menu"
import InquiryItems from "./Forms/InquiryItems"
import { Link } from "gatsby"
import { Box, Grid, makeStyles, Paper, Typography } from "@material-ui/core"
import { Thermometer } from "react-feather"
import InquiryContext from "./context/InquiryContext"

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(6, 2, 4, 2),
  },
  marquee: {
    fontSize: "36px",
    letterSpacing: "1.5px",
    fontWeight: "light",
    textTransform: "uppercase",
    lineHeight: "97%",
    textAlign: "center",
  },
  signIn: {
    padding: theme.spacing(3, 0, 0, 0),
  },
  link: {
    textDecoration: "none",
    color: theme.palette.common.blue,
    fontWeight: "bold",
  },
}))

const InquiryForm = ({ className, ...rest }) => {
  const classes = useStyles()
  const { state, dispatch } = useContext(InquiryContext)
  return (
    <div className={classes.root}>
      <Paper variant="outlined" square className={classes.paper}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography className={classes.marquee}>
              Request a Quote & Samples
            </Typography>
            {state.inquiryUuid.length > 5 ? (
              ""
            ) : (
              <Paper className={classes.paper} elevation={0}>
                <>
                  <Typography variant="body1" gutterBottom>
                    We're glad you made it to our quote and sample request form.
                    We quote each new order individually due to the custom
                    nature of our products.
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    The best way to get started is to use the form on this web
                    app to describe what you want. We'll take a look and send
                    you pricing along with some ideas we think will help your
                    new item look modern and professional.
                  </Typography>
                  <Box className={classes.signIn}>
                    <Typography variant="caption">
                      Have an account?{" "}
                      <Link className={classes.link} to={"/my/"}>
                        Sign in
                      </Link>{" "}
                      for a personalized experience.
                    </Typography>
                  </Box>
                </>
              </Paper>
            )}
          </Grid>
        </Grid>
        <InquiryPerson />
        <Menu />
        <InquiryItems />
        <InquirySubmit />
      </Paper>
    </div>
  )
}

export default InquiryForm
