import React, { useContext, useState, Fragment } from "react"
import { makeStyles } from "@material-ui/core/styles"

import gql from "graphql-tag"
import { useMutation } from "@apollo/client"

import {
  Box,
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Collapse,
  Typography,
} from "@material-ui/core"
import AddIcon from "@material-ui/icons/Add"
import DoneIcon from "@material-ui/icons/Done"
import InquiryContext from "../context/InquiryContext"
import InquiryAddressForm from "./PersonForms/InquiryAddressForm"
import ThankYou from "../ThankYou"
import { useSiteMetadata } from "../../utils/hooks/useSiteMetadata"

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    // padding: theme.spacing(2),
  },
  avatar: {
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.secondary.main,
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  samplesTitle: {
    fontSize: "22px",
    letterSpacing: "1.5px",
    fontWeight: "light",
    textTransform: "uppercase",
  },
  samplesButton: {
    marginTop: theme.spacing(2),
  },
  box: {
    border: "1px solid #808080",
    margin: "10px auto",
    // backgroundColor: theme.palette.common.black,
    // color: theme.palette.common.white,
  },
}))

const inq = [
  { id: 1, name: "hi" },
  { id: 2, name: "man" },
]

const InquiryConfirm = ({ items }) => {
  const classes = useStyles()
  const { state, dispatch } = useContext(InquiryContext)
  const [hideButtons, setHideButtons] = useState(true)
  const [showSubmit, setShowSubmit] = useState(false)
  const [samples, setSamples] = useState(false)
  const [complete, setComplete] = useState(false)
  const showMenu = state.showMenu
  const itemSwitch = state.itemSwitch
  const { sbuId } = useSiteMetadata()
  // const [sampleRequest, { loading, error }] = useMutation(
  //   EDIT_INQUIRY_MUTATION,
  //   {
  //     variables: {
  //       businessUnitId: sbuId,
  //       inquiryPersonId: state.inquiryPersonId,
  //       source: "iconemblem.com",
  //       spam: false,
  //       quoteCreated: true,
  //       samplesRequested: true,
  //     },
  //     onCompleted: (data, response, errors) => {
  //       return <InquiryAddressForm />
  //       // handleInquiryAddress()
  //     },
  //   }
  // )
  // const handleInquiryAddress = event => {
  //   return <InquiryAddressForm />
  // }
  const handleAddAnother = () => {
    dispatch({
      type: "TOGGLE_SHOW_MENU",
    })
  }
  const handleSamples = () => {
    setHideButtons(false)
    setShowSubmit(true)
  }
  if (items === undefined) return ""
  if (items.length === 0) return ""

  if (samples === true) {
    return <InquiryAddressForm />
  }
  if (complete === true) return <ThankYou />
  return (
    <>
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h6" color="inherit">
              {items
                ? `Items in this inquiry: ${items.length}`
                : "No items in this inquiry"}
            </Typography>
            <Divider />
            <List className={classes.root}>
              {items.map((i, index) => {
                const title = i.title

                return (
                  <Fragment key={index}>
                    <ListItem>
                      <ListItemIcon>
                        <DoneIcon />
                      </ListItemIcon>
                      <ListItemText primary={i.title} secondary={i.itemType} />
                    </ListItem>
                    <Divider />
                  </Fragment>
                )
              })}
            </List>
          </Grid>

          {hideButtons && (
            <>
              <Grid item container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={showMenu || itemSwitch != "added"}
                    onClick={handleAddAnother}
                    startIcon={<AddIcon />}
                  >
                    Add Another Item
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={handleSamples}
                    startIcon={<DoneIcon />}
                  >
                    Complete Inquiry
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
          <Box>
            {showSubmit && (
              <>
                <Grid item container spacing={3}>
                  <Grid item xs={12}>
                    <Box p={3} className={classes.box}>
                      <Typography className={classes.samplesTitle} gutterBottom>
                        Samples
                      </Typography>
                      <Typography variant="body1" gutterBottom>
                        Some of our customers prefer to see some random samples
                        to check our quality before placing an order.{" "}
                      </Typography>

                      <Typography variant="body2">
                        Would you like some samples in the mail?
                      </Typography>

                      <Button
                        fullWidth
                        variant="outlined"
                        className={classes.samplesButton}
                        onClick={() => setSamples(true)}
                      >
                        Yes, please mail samples.
                      </Button>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="secondary"
                      onClick={() => setComplete(true)}
                    >
                      Submit your inquiry now
                    </Button>
                  </Grid>
                </Grid>
              </>
            )}
          </Box>
        </Grid>
      </div>
    </>
  )
}
const EDIT_INQUIRY_MUTATION = gql`
  mutation(
    $businessUnitId: Int
    $inquiryPersonId: Int
    $source: String
    $spam: Boolean
    $quoteCreated: Boolean
    $samplesRequested: Boolean
  ) {
    editInquiry(
      businessUnitId: $businessUnitId
      inquiryPersonId: $inquiryPersonId
      source: $source
      spam: $spam
      quoteCreated: $quoteCreated
      samplesRequested: $samplesRequested
    ) {
      inquiry {
        id
        inquiryUuid
        samplesRequested
        inquiryPerson {
          id
          firstName
          lastName
          orgName
        }
        createdAt
        businessUnit {
          id
          name
        }
      }
    }
  }
`
export default InquiryConfirm
