import React, { useState } from "react"
import InquiryPersonInfoForm from "./PersonForms/InquiryPersonInfoForm"

import { Grid, makeStyles, Paper, Typography } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },

  welcome: {
    color: "textPrimary",
    align: "center",
    variant: "h3",
  },
  name: {
    fontSize: "22px",
    letterSpacing: "1.2px",
    fontWeight: "light",
    color: theme.palette.common.black,
    textTransform: "uppercase",
    lineHeight: "97%",
  },
}))

const InquiryPerson = ({ className }) => {
  const classes = useStyles()
  const [firstName, setFirstName] = useState("")
  const [hasPerson, setHasPerson] = useState(false)

  if (hasPerson === true && firstName.length > 0)
    return (
      <>
        {/* <Grid container spacing={3}>
          <Paper square elevation={0} className={classes.paper}>
            <Typography className={classes.name}>
              Welcome, {firstName}
            </Typography>
          </Paper>
        </Grid> */}
      </>
    )
  return (
    <>
      <Grid container spacing={3}>
        <Paper square elevation={0} className={classes.paper}>
          <InquiryPersonInfoForm
            firstName={firstName}
            setFirstName={setFirstName}
            setHasPerson={setHasPerson}
          />
        </Paper>
      </Grid>
    </>
  )
}

export default InquiryPerson
