import React from "react"
import { Collapse } from "@material-ui/core/Collapse"
const LapelPinsInquiry = () => {
  return (
    // <Collapse in timeout="auto" unmountOnExit>
    <>
      <div>lapel pins inquiry form</div>
      <div>lapel pins inquiry form</div>
      <div>lapel pins inquiry form</div>
      <div>lapel pins inquiry form</div>
      <div>lapel pins inquiry form</div>
      <div>lapel pins inquiry form</div>
      <div>lapel pins inquiry form</div>
      <div>lapel pins inquiry form</div>
      <div>lapel pins inquiry form</div>
      <div>lapel pins inquiry form</div>
      <div>lapel pins inquiry form</div>
      <div>lapel pins inquiry form</div>
      <div>lapel pins inquiry form</div>
      <div>lapel pins inquiry form</div>
    </>
    // </Collapse>
  )
}

export default LapelPinsInquiry
