import React, { useContext } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
import CardActionArea from "@material-ui/core/CardActionArea"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"
import InquiryContext from "../context/InquiryContext"
import Image from "gatsby-image"
const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 300,
    height: 150,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },

  cover: {
    width: 150,
  },
  container: {
    display: "flex",
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  cardTitle: {
    fontSize: "1.1rem",
    color: "#122740",
    textTransform: "uppercase",
    fontVariant: "small-caps",
  },
}))

const MenuCard = ({ name, switchName, image }) => {
  const classes = useStyles()
  const { dispatch } = useContext(InquiryContext)

  const handlePickClick = () => {
    dispatch({
      type: "SET_ITEM_SWITCH",
      payload: switchName,
    })
    dispatch({
      type: "TOGGLE_SHOW_MENU",
    })
  }

  return (
    <>
      <Card square className={classes.root}>
        <CardActionArea onClick={() => handlePickClick(switchName)}>
          <Image className={classes.img} fixed={image} />

          <div className={classes.details}>
            <CardContent className={classes.content}>
              <Typography className={classes.cardTitle} color="textPrimary">
                {name}
              </Typography>
            </CardContent>
          </div>
          {/* <CardMedia
            className={classes.cover}
            image=""
            title=""
          /> */}
        </CardActionArea>
      </Card>
    </>
  )
}
export default MenuCard
