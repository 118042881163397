import React from "react"
import InquiryPerson from "./Forms/InquiryPerson"
import InquirySubmit from "./Forms/InquirySubmit"
import Menu from "./Menu"
import InquiryItems from "./Forms/InquiryItems"
import { Link } from "gatsby"
import { Grid, makeStyles, Paper, Typography } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
  },
  marquee: {
    fontSize: "20px",
    letterSpacing: "1.5px",
    fontWeight: "light",
    textTransform: "uppercase",
    lineHeight: "97%",
    textAlign: "center",
  },
}))

const ThankYou = ({ className, ...rest }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Paper variant="outlined" square className={classes.paper}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography className={classes.marquee}>Thank You</Typography>
            <Typography className={classes.marquee}>
              Quick customer satisfaction survey. How was that experience? Any
              suggestions? Link to blog posts. Have one for each type of
              targeted customer. Designer. Administrator. Reseller. Parent.
              Thinker.
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </div>
  )
}

export default ThankYou
