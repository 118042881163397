import React, { useState, useEffect, useContext } from "react"
import gql from "graphql-tag"
import { useMutation } from "@apollo/client"
import clsx from "clsx"
import PropTypes from "prop-types"
import {
  Avatar,
  AutoComplete,
  Box,
  Button,
  ButtonBase,
  ClickAwayListener,
  Container,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  Popover,
  TextField,
  Typography,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import { Link, navigate } from "gatsby"

import LockOutlinedIcon from "@material-ui/icons/LockOutlined"
import BackspaceOutlinedIcon from "@material-ui/icons/BackspaceOutlined"
import ChevronLeft from "@material-ui/icons/ChevronLeft"
import PhotoCamera from "@material-ui/icons/PhotoCamera"

import InquiryContext from "../../context/InquiryContext"
import { HowToVoteRounded } from "@material-ui/icons"

// import Error from "../../../../../Utils/Error"
// import Loading from "../../../../../Utils/Loading"
// import useDidMountEffect from "../../../../../Global/hooks/useDidMountEffect"
const backgroundHelp = () => {
  return (
    <div style={{ padding: "10px" }}>
      <p>
        <strong>Twill:</strong>By far the the most popular and practical option.
        Patch utilizes the twill's durable polyester material as the base and
        background color.
      </p>
      <p>
        <strong>100 Percent:</strong>Design is completely filled in with
        embroidery thread. Designs with 100% embroidered backgrounds require
        more stitches than twill patches and tend to cost more because theytake
        longer to sew.
      </p>
      <p>
        <strong>Felt:</strong>A premium background material, felt is often used
        for patches that go on wool coats and jackets because they have a wooly
        look and touch.
      </p>
      <p>
        <strong>Vinyl:</strong>Vinyl is a premium material looks great and holds
        up on patches for leather jackets.
      </p>
      <p>Please see the gallery for examples.</p>
    </div>
  )
}

const borderHelp = () => {
  return (
    <div style={{ padding: "10px" }}>
      <p>
        <strong>Merrowed Edge:</strong> A traditional finish where thread wraps
        around the outer edge of the patch. Best for basic shapes and badges.
      </p>
      <p>
        <strong>Laser Cut:</strong> Border is cut out in the shape of the
        design. Edge is not finished with a merrowing machine.
      </p>
      <p>See the gallery for examples.</p>
    </div>
  )
}
const backingHelp = () => {
  return (
    <div style={{ padding: "10px" }}>
      <p>
        <strong>Plastic (PVC):</strong> A durable coating on the back of the
        patch to keep it looking nice and wrinkle-free over time.
      </p>
      <p>
        <strong>Heat Seal Glue:</strong>A heat activated glue that will
        permanently attach your patch to the garment. A heat press (recommended)
        or iron can be used to activate the glue.
      </p>
      <p>
        <strong>Adhesive:</strong>Peel and stick for a quick temporary hold.
      </p>
      <p>
        <strong>Hook and Loop:</strong>Commonly referred to as Velcro.
      </p>
      <p>
        <strong>Magnet:</strong>Premium backing. Extra shipping charges may
        apply due to extra weight.
      </p>
      <p>
        <strong>Pellon:</strong>Minimal backing. Soft pellon material.
      </p>
    </div>
  )
}
const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 0),
  },
  input: {
    display: "none",
  },
  categoryHead: {
    fontSize: "26px",
    letterSpacing: "1.2px",
    fontWeight: "light",
    textTransform: "uppercase",
    lineHeight: "97%",
  },
  smallDivideSpace: {
    padding: theme.spacing(1, 0, 1, 0),
  },
  mediumDivideSpace: {
    padding: theme.spacing(1, 0, 1, 0),
  },
  link: {
    textDecoration: "none",
    color: theme.palette.common.blue,
  },
  popover: {
    //   pointerEvents: "none",
    padding: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(1),
    width: "300px",
  },
}))

const EmbroideredPatchesInquiry = ({
  className,

  // setEditing,
  setItems,
  setActiveStep,
  item,
  specToEdit,
  spec,
  editing,
  ...rest
}) => {
  const classes = useStyles()
  const { state, dispatch } = useContext(InquiryContext)
  const [specs, setSpecs] = useState({
    title: "",
    height: "",
    width: "",
    background: "",
    backgroundColor: "",
    threadColors: "",
    border: "",
    borderColor: "",
    backing: "",
    note: "",
    qty: "",
  })
  const specstring = JSON.stringify(specs)

  const inquiryUuid = state.inquiryUuid

  const [file, setFile] = useState("")
  const [fileError, setFileError] = useState("")
  const [helpBox, setHelpBox] = useState("")
  const [anchorEl, setAnchorEl] = useState(null)
  // const [open, setOpen] = useState(false)
  const open = Boolean(anchorEl)
  const [handleSubmit] = useMutation(
    CREATE_EMBROIDERED_PATCH_INQUIRY_MUTATION,
    {
      variables: {
        inquiryUuid: inquiryUuid,
        description: specstring,
        attachment: file,
        inquiryItemTypeId: 1,
        note: specstring,
      },
      onCompleted: (data, response, errors) => {
        console.log(data)
        let itemData = data.createInquiryItem.inquiryItem
        console.log(itemData)
        const itemType = "Embroidered Patches"
        const title = specs.title
        const newItem = { itemType, title }
        setItems(items => [...items, newItem])
        handleSwitch()
      },
    }
  )

  const handleSwitch = () => {
    dispatch({
      type: "SET_ITEM_SWITCH",
      payload: "added",
    })
  }

  const handleEditSubmit = () => {
    console.log("have not done this yet")
  }

  const handleChange = e => {
    const { name, value } = e.target
    setSpecs(prevState => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleImageChange = event => {
    const selectedFile = event.target.files[0]
    const fileSizeLimit = 10000000 // 10mb
    if (selectedFile && selectedFile.size > fileSizeLimit) {
      setFileError(`${selectedFile.name}: File size too large`)
    } else {
      setFile(selectedFile)
      setFileError("")
    }
  }

  const handleGoBack = () => {
    dispatch({
      type: "SET_ITEM_SWITCH",
      payload: "inactive",
    })
    dispatch({
      type: "TOGGLE_SHOW_MENU",
    })
  }

  const handleOpenPop = (event, value) => {
    setAnchorEl(event.currentTarget)
    setHelpBox(value)
    // setOpen(true)
  }

  const handlePopoverClose = () => {
    // setOpen(false)
    setHelpBox("")
    setAnchorEl(null)
  }

  const backgrounds = [
    {
      id: 1,
      name: "Twill",
      value: "twill",
      description: "twill description",
    },
    {
      id: 2,
      name: "100% Embroidery",
      value: "100percent",
      description: "hundred percent description",
    },
    {
      id: 3,
      name: "Felt",
      value: "felt",
      description: "Felt description",
    },
    {
      id: 4,
      name: "Vinyl",
      value: "vinyl",
      description: "Vinyl description",
    },
  ]

  const borders = [
    {
      id: 1,
      name: "Merrowed Edge",
      value: "merrowed",
    },
    {
      id: 2,
      name: "Laser Cut",
      value: "lasercut",
    },
  ]

  const backings = [
    {
      id: 1,
      name: "Heat Seal Glue",
      value: "heatseal",
    },
    {
      id: 2,
      name: "Plastic",
      value: "plastic",
    },
    {
      id: 3,
      name: "Adhesive (sticker)",
      value: "adhesive",
    },
    {
      id: 4,
      name: "Hook and Loop (1 side only)",
      value: "hookloop1",
    },
    {
      id: 5,
      name: "Hook and Loop (2 sides)",
      value: "hookloop2",
    },
    {
      id: 6,
      name: "Magnet",
      value: "magnet",
    },
    {
      id: 7,
      name: "Pellon",
      value: "pellon",
    },
  ]
  return (
    <>
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="flex-start"
        spacing={3}
      >
        <Grid item xs={12} sm={2}>
          <Button
            fullWidth
            variant="outlined"
            color="primary"
            size="small"
            startIcon={<ChevronLeft />}
            onClick={handleGoBack}
          >
            Back
          </Button>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.mediumDivideSpace} />
          <Typography variant="h5" className={classes.categoryHead}>
            Embroidered Patches
          </Typography>
        </Grid>
      </Grid>
      <div className={classes.smallDivideSpace} />
      <FormControl>
        <form
          onSubmit={event => {
            event.preventDefault()
            handleSubmit()
          }}
          className={clsx(classes.root, className)}
          {...rest}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                label="Patch Title"
                margin="dense"
                size="small"
                name="title"
                onChange={handleChange}
                type="text"
                value={specs.title}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label="Width (inches)"
                margin="dense"
                size="small"
                name="width"
                // onBlur={handleBlur}
                onChange={handleChange}
                // onChange={event => setWidth(event.target.value)}
                type="text"
                value={specs.width}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label="Height (inches)"
                margin="dense"
                size="small"
                name="height"
                // onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={specs.height}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label="How many?"
                margin="dense"
                size="small"
                name="qty"
                helperText="Price each depends on the order size. Please give us a general idea. ex.'100, 300' or '1000-3000' and we'll send you a range of prices. Minimum order quantity is 25 patches per design."
                onChange={handleChange}
                type="text"
                value={specs.qty}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                select
                SelectProps={{ native: true }}
                label="Background Style"
                margin="dense"
                size="small"
                name="background"
                // onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={specs.background}
                variant="outlined"
              >
                <option></option>
                {backgrounds.map(bg => (
                  <option key={bg.id} value={bg.value}>
                    {bg.name}
                  </option>
                ))}
              </TextField>
              <ButtonBase
                onClick={event => handleOpenPop(event, backgroundHelp)}
              >
                Background Options (quick look)
              </ButtonBase>

              <Popover
                id="options-popover"
                open={open}
                onClose={handlePopoverClose}
                anchorEl={anchorEl}
                className={classes.popover}
                classes={{
                  paper: classes.paper,
                }}
              >
                {helpBox}
              </Popover>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                select
                SelectProps={{ native: true }}
                label="Border Style"
                margin="dense"
                size="small"
                name="border"
                // onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={specs.border}
                variant="outlined"
              >
                <option></option>
                {borders.map(b => (
                  <option key={b.id} value={b.value}>
                    {b.name}
                  </option>
                ))}
              </TextField>
              <ButtonBase onClick={event => handleOpenPop(event, borderHelp)}>
                Border Styles (quick look)
              </ButtonBase>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                select
                SelectProps={{ native: true }}
                label="Backing"
                margin="dense"
                size="small"
                name="backing"
                // onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={specs.backing}
                variant="outlined"
              >
                <option></option>
                {backings.map(bk => (
                  <option key={bk.id} value={bk.value}>
                    {bk.name}
                  </option>
                ))}
              </TextField>
              <ButtonBase onClick={event => handleOpenPop(event, backingHelp)}>
                Backing Styles (quick look)
              </ButtonBase>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label="Thread Colors"
                helperText="(optional)"
                margin="dense"
                size="small"
                name="threadColors"
                // onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={specs.threadColors}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label="Background Color"
                helperText="(optional)"
                margin="dense"
                size="small"
                name="backgroundColor"
                // onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={specs.backgroundColor}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label="Border Color"
                helperText="(optional)"
                margin="dense"
                size="small"
                name="borderColor"
                // onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={specs.borderColor}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                multiline
                label="Notes, questions, comments..."
                helperText="(optional)"
                margin="dense"
                size="small"
                name="note"
                // onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={specs.note}
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12} sm={8}>
              {/* <FormControl> */}
              <input
                id="imageUpload"
                type="file"
                accept="image/*"
                className={classes.input}
                onChange={handleImageChange}
              />
              <label htmlFor="imageUpload">
                <Button
                  variant="outlined"
                  fullWidth
                  color={file ? "primary" : "inherit"}
                  component="span"
                  // className={classes.button}
                  startIcon={<PhotoCamera />}
                >
                  {file ? "File Selected" : "Upload an Image File"}
                </Button>
              </label>
              {file && file.name}
              <FormHelperText>{fileError}</FormHelperText>
              {/* </FormControl> */}
            </Grid>
            <Grid item xs={12} sm={4}>
              {editing ? (
                <Button
                  fullWidth
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Update Embroidered Patches Spec
                </Button>
              ) : (
                <Button
                  fullWidth
                  type="submit"
                  color="primary"
                  variant="outlined"
                >
                  Add Item and Continue
                </Button>
              )}
            </Grid>
          </Grid>
        </form>
      </FormControl>
    </>
  )
}

const CREATE_EMBROIDERED_PATCH_INQUIRY_MUTATION = gql`
  mutation(
    $inquiryUuid: String
    $inquiryItemTypeId: Int
    $description: String
    $note: String
    $attachment: Upload
  ) {
    createInquiryItem(
      inquiryUuid: $inquiryUuid
      inquiryItemTypeId: $inquiryItemTypeId
      description: $description
      note: $note
      attachment: $attachment
    ) {
      inquiryItem {
        id
        description
        inquiryItemType {
          name
        }
        note
      }
    }
  }
`
EmbroideredPatchesInquiry.propTypes = {
  className: PropTypes.string,
  // onSubmitSuccess: PropTypes.func,
}

// EmbroideredPatchesSpecs.default = {
//   onSubmitSuccess: () => {},
// }
export default EmbroideredPatchesInquiry
