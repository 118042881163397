import React, { useState, useContext, useEffect } from "react"

import {
  Button,
  Collapse,
  Typography,
  FormControl,
  Paper,
  TextField,
  Divider,
  Grid,
  CardActionArea,
  makeStyles,
} from "@material-ui/core"
import { useSiteMetadata } from "../../../utils/hooks/useSiteMetadata"
import gql from "graphql-tag"
import { useQuery, useMutation } from "@apollo/client"
import Loading from "../../../utils/Loading"
import Error from "../../../utils/Error"

import InquiryContext from "../../context/InquiryContext"
import ThankYou from "../../ThankYou"

const regions = [
  {
    id: 1,
    name: "United States",
  },
  {
    id: 2,
    name: "Canada",
  },
  {
    id: 153,
    name: "Mexico",
  },
  {
    id: 17,
    name: "Australia",
  },
  {
    id: 32,
    name: "Brazil",
  },
  {
    id: 49,
    name: "China",
  },
  {
    id: 76,
    name: "France (Metro)",
  },

  {
    id: 95,
    name: "Hong Kong",
  },
  {
    id: 104,
    name: "India",
  },
  {
    id: 102,
    name: "Ireland",
  },
  {
    id: 103,
    name: "Israel",
  },
  {
    id: 109,
    name: "Italy",
  },
  {
    id: 112,
    name: "Japan",
  },

  {
    id: 174,
    name: "Pakistan",
  },
  {
    id: 175,
    name: "Poland",
  },
  {
    id: 241,
    name: "Serbia",
  },
  {
    id: 68,
    name: "Spain",
  },
  {
    id: 193,
    name: "Sweden",
  },
  {
    id: 222,
    name: "Taiwan",
  },
  {
    id: 212,
    name: "Thailand",
  },
  {
    id: 78,
    name: "United Kingdom",
  },
  {
    id: 224,
    name: "Ukraine",
  },
  {
    id: 235,
    name: "Vietnam",
  },
  {
    id: 3,
    name: "Other International Region",
  },
]

const usaStates = [
  {
    name: "Alabama",
    abbreviation: "AL",
  },
  {
    name: "Alaska",
    abbreviation: "AK",
  },
  {
    name: "American Samoa",
    abbreviation: "AS",
  },
  {
    name: "Arizona",
    abbreviation: "AZ",
  },
  {
    name: "Arkansas",
    abbreviation: "AR",
  },
  {
    name: "California",
    abbreviation: "CA",
  },
  {
    name: "Colorado",
    abbreviation: "CO",
  },
  {
    name: "Connecticut",
    abbreviation: "CT",
  },
  {
    name: "Delaware",
    abbreviation: "DE",
  },
  {
    name: "District Of Columbia",
    abbreviation: "DC",
  },
  {
    name: "Federated States Of Micronesia",
    abbreviation: "FM",
  },
  {
    name: "Florida",
    abbreviation: "FL",
  },
  {
    name: "Georgia",
    abbreviation: "GA",
  },
  {
    name: "Guam",
    abbreviation: "GU",
  },
  {
    name: "Hawaii",
    abbreviation: "HI",
  },
  {
    name: "Idaho",
    abbreviation: "ID",
  },
  {
    name: "Illinois",
    abbreviation: "IL",
  },
  {
    name: "Indiana",
    abbreviation: "IN",
  },
  {
    name: "Iowa",
    abbreviation: "IA",
  },
  {
    name: "Kansas",
    abbreviation: "KS",
  },
  {
    name: "Kentucky",
    abbreviation: "KY",
  },
  {
    name: "Louisiana",
    abbreviation: "LA",
  },
  {
    name: "Maine",
    abbreviation: "ME",
  },
  {
    name: "Marshall Islands",
    abbreviation: "MH",
  },
  {
    name: "Maryland",
    abbreviation: "MD",
  },
  {
    name: "Massachusetts",
    abbreviation: "MA",
  },
  {
    name: "Michigan",
    abbreviation: "MI",
  },
  {
    name: "Minnesota",
    abbreviation: "MN",
  },
  {
    name: "Mississippi",
    abbreviation: "MS",
  },
  {
    name: "Missouri",
    abbreviation: "MO",
  },
  {
    name: "Montana",
    abbreviation: "MT",
  },
  {
    name: "Nebraska",
    abbreviation: "NE",
  },
  {
    name: "Nevada",
    abbreviation: "NV",
  },
  {
    name: "New Hampshire",
    abbreviation: "NH",
  },
  {
    name: "New Jersey",
    abbreviation: "NJ",
  },
  {
    name: "New Mexico",
    abbreviation: "NM",
  },
  {
    name: "New York",
    abbreviation: "NY",
  },
  {
    name: "North Carolina",
    abbreviation: "NC",
  },
  {
    name: "North Dakota",
    abbreviation: "ND",
  },
  {
    name: "Northern Mariana Islands",
    abbreviation: "MP",
  },
  {
    name: "Ohio",
    abbreviation: "OH",
  },
  {
    name: "Oklahoma",
    abbreviation: "OK",
  },
  {
    name: "Oregon",
    abbreviation: "OR",
  },
  {
    name: "Palau",
    abbreviation: "PW",
  },
  {
    name: "Pennsylvania",
    abbreviation: "PA",
  },
  {
    name: "Puerto Rico",
    abbreviation: "PR",
  },
  {
    name: "Rhode Island",
    abbreviation: "RI",
  },
  {
    name: "South Carolina",
    abbreviation: "SC",
  },
  {
    name: "South Dakota",
    abbreviation: "SD",
  },
  {
    name: "Tennessee",
    abbreviation: "TN",
  },
  {
    name: "Texas",
    abbreviation: "TX",
  },
  {
    name: "Utah",
    abbreviation: "UT",
  },
  {
    name: "Vermont",
    abbreviation: "VT",
  },
  {
    name: "Virgin Islands",
    abbreviation: "VI",
  },
  {
    name: "Virginia",
    abbreviation: "VA",
  },
  {
    name: "Washington",
    abbreviation: "WA",
  },
  {
    name: "West Virginia",
    abbreviation: "WV",
  },
  {
    name: "Wisconsin",
    abbreviation: "WI",
  },
  {
    name: "Wyoming",
    abbreviation: "WY",
  },
]

const canadaProvinces = [
  {
    name: "Alberta",
    abbreviation: "AB",
  },
  {
    name: "British Columbia",
    abbreviation: "BC",
  },
  {
    name: "Manitoba",
    abbreviation: "MB",
  },
  {
    name: "New Brunswick",
    abbreviation: "BN",
  },
  {
    name: "Newfoundland and Labrador",
    abbreviation: "NL",
  },
  {
    name: "Nova Scotia",
    abbreviation: "NS",
  },
  {
    name: "Ontario",
    abbreviation: "ON",
  },
  {
    name: "Prince Edward Island",
    abbreviation: "PE",
  },
  {
    name: "Quebec",
    abbreviation: "QC",
  },
  {
    name: "Saskatchewan",
    abbreviation: "SK",
  },
  {
    name: "Northwest Territories",
    abbreviation: "NT",
  },
  {
    name: "Nunavut",
    abbreviation: "NU",
  },
  {
    name: "Yukon",
    abbreviation: "YT",
  },
]

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  hide: {
    display: "none",
  },
  show: {},
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  formSection: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  infoSection: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}))

const InquiryAddressForm = () => {
  const classes = useStyles()
  const { state } = useContext(InquiryContext)
  const [submitting, setSubmitting] = useState(false)
  const [region, setRegion] = useState(1)
  const [regionSetting, setRegionSetting] = useState("USA")
  const [findMoreRegions, setFindMoreRegions] = useState(false)
  const [allRegions, setAllRegions] = useState([])

  const [regionId, setRegionId] = useState("")
  const [attention, setAttention] = useState("")
  const [addressLine1, setAddressLine1] = useState("")
  const [addressLine2, setAddressLine2] = useState("")
  const [addressLine3, setAddressLine3] = useState("")
  const [city, setCity] = useState("")
  const [zone, setZone] = useState("")
  const [postalCode, setPostalCode] = useState("")
  const [inquiryAddressUid, setInquiryAddressUid] = useState("")
  const [collapse, setCollapse] = useState(false)
  const [thankYou, setThankYou] = useState(false)
  const inquiryUuid = state.inquiryUuid
  const { title } = useSiteMetadata()
  const { data, loading, error } = useQuery(REGIONS_QUERY, {
    onCompleted: data => {
      setAllRegions(data.regions)
      console.log(data.regions)
    },
  })

  useEffect(() => {
    console.log(region)
    if (region == 3) setFindMoreRegions(true)
    region == 1
      ? setRegionSetting("USA")
      : region == 2
      ? setRegionSetting("Canada")
      : setRegionSetting("other")
  }, [region])

  const [handleSubmit] = useMutation(NEW_INQUIRY_ADDRESS_MUTATION, {
    variables: {
      regionId: region,
      inquiryUuid: inquiryUuid,
      samplesRequested: true,
      attention: attention,
      addressLine1: addressLine1,
      addressLine2: addressLine2,
      addressLine3: addressLine3,
      city: city,
      zone: zone,
      postalCode: postalCode,
    },
    onCompleted: (data, response, errors) => {
      console.log(data)
      setSubmitting(false)
      setInquiryAddressUid(data.createInquiryAddress.inquiryAddress.uid)
      nextStep()
    },
    onError: err => console.error(err),
  })

  const handleRegionChange = event => {
    event.persist()

    let value = ""

    if (event.target.value !== 3) {
      value = event.target.value
    }
    if (event.target.value === 3) {
      setFindMoreRegions(true)
    }
    setRegion(value)
  }

  const nextStep = () => {
    setThankYou(true)
  }

  if (loading) return <Loading />
  if (error) return <Error />
  if (thankYou === true) return <ThankYou />

  return (
    <div className={classes.infoSection}>
      <Paper elevation={0} square>
        <form
          onSubmit={event => {
            event.preventDefault()
            setSubmitting(true)
            handleSubmit(event)
          }}
        >
          <Typography>Where do you live?</Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                select
                className={findMoreRegions ? classes.hide : classes.show}
                helperText="(required)"
                value={region}
                onChange={handleRegionChange}
                SelectProps={{ native: true }}
                variant="outlined"
              >
                {regions.map(r => (
                  <option key={r.id} value={r.id}>
                    {r.name}
                  </option>
                ))}
              </TextField>
              <TextField
                fullWidth
                select
                label="More Regions"
                className={findMoreRegions ? classes.show : classes.hide}
                helperText="(required)"
                value={region}
                onChange={handleRegionChange}
                SelectProps={{ native: true }}
                variant="outlined"
              >
                {allRegions.map(r => (
                  <option key={r.id} value={r.id}>
                    {r.name}
                  </option>
                ))}
              </TextField>
            </Grid>

            <Grid item sm={6}></Grid>
            <>
              <Grid item xs={12} sm={5}>
                <FormControl required fullWidth>
                  <TextField
                    label="Address"
                    id="addressLine1"
                    helperText="(required)"
                    value={addressLine1}
                    onChange={event => setAddressLine1(event.target.value)}
                    variant="outlined"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl required fullWidth>
                  <TextField
                    label={
                      regionSetting === "USA"
                        ? "(Apt, Unit, Box...)"
                        : "Address Line 2"
                    }
                    id="addressLine2"
                    value={addressLine2}
                    onChange={event => setAddressLine2(event.target.value)}
                    variant="outlined"
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={3}>
                <FormControl required fullWidth>
                  <TextField
                    className={
                      regionSetting === "USA"
                        ? classes.hide
                        : regionSetting === "Canada"
                        ? classes.hide
                        : classes.show
                    }
                    label="Address Line 3"
                    id="addressLine3"
                    value={addressLine3}
                    onChange={event => setAddressLine3(event.target.value)}
                    variant="outlined"
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={5}>
                <TextField
                  label="City"
                  id="city"
                  value={city}
                  fullWidth
                  onChange={event => setCity(event.target.value)}
                  variant="outlined"
                />
              </Grid>
              <Divider />

              <Grid item xs={12} sm={4}>
                <FormControl fullWidth required>
                  <TextField
                    select
                    className={
                      regionSetting === "other" ? classes.hide : classes.show
                    }
                    label={
                      regionSetting === "USA"
                        ? "State"
                        : regionSetting === "Canada"
                        ? "Province"
                        : "State, Province or Zone"
                    }
                    id="zone"
                    type="text"
                    helperText="(required)"
                    variant="outlined"
                    value={zone}
                    onChange={event => setZone(event.target.value)}
                    SelectProps={{ native: true }}
                  >
                    <option></option>
                    {regionSetting === "USA"
                      ? usaStates.map((us, index) => (
                          <option key={index} value={us.abbreviation}>
                            {us.name}
                          </option>
                        ))
                      : regionSetting === "Canada"
                      ? canadaProvinces.map((ca, index) => (
                          <option key={index} value={ca.abbreviation}>
                            {ca.name}
                          </option>
                        ))
                      : ""}
                  </TextField>

                  <TextField
                    label="State, Province or Zone"
                    id="zone"
                    type="text"
                    helperText="(required)"
                    variant="outlined"
                    className={
                      regionSetting === "other" ? classes.show : classes.hide
                    }
                    value={zone}
                    onChange={event => setZone(event.target.value)}
                    SelectProps={{ native: true }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={3}>
                <FormControl>
                  <TextField
                    label="Postal Code"
                    id="postalCode"
                    type="text"
                    helperText="(required)"
                    variant="outlined"
                    value={postalCode}
                    onChange={event => setPostalCode(event.target.value)}
                  />
                </FormControl>
              </Grid>
            </>

            <Divider />

            <Grid item xs={12} sm={8}>
              {title} respects your privacy. We will not share or sell this
              information. Please see our privacy policy.
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={submitting || !addressLine1.trim()}
                >
                  {loading ? "Sending..." : "Complete Your Inquiry"}
                </Button>
              </FormControl>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </div>
    // </Collapse>
  )
}

const REGIONS_QUERY = gql`
  query {
    regions {
      id
      name
    }
  }
`
const NEW_INQUIRY_ADDRESS_MUTATION = gql`
  mutation(
    $inquiryUuid: String
    $regionId: Int
    $samplesRequested: Boolean
    $attention: String
    $addressLine1: String
    $addressLine2: String
    $addressLine3: String
    $zone: String
    $city: String
    $postalCode: String
  ) {
    createInquiryAddress(
      inquiryUuid: $inquiryUuid
      regionId: $regionId
      samplesRequested: $samplesRequested
      attention: $attention
      addressLine1: $addressLine1
      addressLine2: $addressLine2
      addressLine3: $addressLine3
      city: $city
      zone: $zone
      postalCode: $postalCode
    ) {
      inquiryAddress {
        uid
        inquiry {
          id
          submitted
        }
        region {
          id
          name
          label
          worldRegion
          globalRegion
        }
        attention
        addressLine1
        addressLine2
        addressLine3
        zone
        city
        postalCode
      }
    }
  }
`

export default InquiryAddressForm
