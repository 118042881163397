import React, { useContext } from "react"
import InquiryContext from "../context/InquiryContext"
import MenuCard from "./MenuCard"
import { graphql, useStaticQuery, Link } from "gatsby"

import {
  Collapse,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core"

const items = [
  { id: 1, name: "Embroidered Patches", switchName: "patches" },
  { id: 2, name: "Lapel Pins", switchName: "pins" },
  { id: 3, name: "Medals", switchName: "medals" },
  { id: 4, name: "Coins", switchName: "medals" },
  { id: 5, name: "Jax", switchName: "medals" },
  { id: 6, name: "Cars", switchName: "medals" },
]

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
  },
}))

const Menu = ({ handleExpand, className, ...rest }) => {
  const classes = useStyles()
  const { state } = useContext(InquiryContext)
  const showMenu = state.showMenu
  const data = useStaticQuery(getImages)
  const patches = data.patches.childImageSharp.fixed
  const pins = data.pins.childImageSharp.fixed

  const items = [
    {
      id: 1,
      name: "Embroidered Patches",
      switchName: "patches",
      image: patches,
    },
    { id: 2, name: "Lapel Pins", switchName: "pins", image: pins },
    { id: 3, name: "Medals", switchName: "medals", image: patches },
    { id: 4, name: "Coins", switchName: "medals", image: patches },
    { id: 5, name: "Jax", switchName: "medals", image: patches },
    { id: 6, name: "Cars", switchName: "medals", image: patches },
  ]

  return (
    <div className={classes.root}>
      <Collapse in={showMenu}>
        <Paper square elevation={0} className={classes.paper}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography style={{ fontSize: "18px" }} component="h4">
                What can we quote for you today ?
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid item container spacing={2}>
                {items.map(item => {
                  const id = item.id
                  const name = item.name
                  const switchName = item.switchName
                  const image = item.image
                  return (
                    <Grid item xs={12} sm={3} key={id}>
                      <MenuCard
                        name={name}
                        switchName={switchName}
                        image={image}
                      />
                    </Grid>
                  )
                })}
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Collapse>
    </div>
  )
}
const getImages = graphql`
  {
    patches: file(relativePath: { eq: "patch_quote_label.jpg" }) {
      childImageSharp {
        fixed(width: 300, height: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    pins: file(relativePath: { eq: "pin_quote_label.jpg" }) {
      childImageSharp {
        fixed(width: 300, height: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
export default Menu
